export const Near = ({ className }) => (
    <svg className={className} viewBox="0 0 56 56">
        <g fill="none">
            <g fill="#000">
                <g>
                    <g>
                        <path
                            d="M20.13 25.343c.258-3.522 3.188-5.146 5.525-5.312 1.02-.073 2.076-.015 3.084.163 1.352.24 2.387 1.074 3.258 2.125 2.839 3.416 5.68 6.831 8.534 10.235 4.635 5.533 9.278 11.058 13.922 16.584 3.95 4.706 7.907 9.403 11.86 14.105.182.22.367.43.69.305.314-.122.29-.404.29-.666 0-10.178.002-20.354-.011-30.53 0-.229-.102-.548-.269-.67-.259-.189-.502.06-.714.244-3.664 3.196-7.33 6.39-10.997 9.584-.594.518-1.138.609-1.68.293-.589-.343-.825-1.05-.463-1.626.517-.814 1.101-1.583 1.65-2.38.438-.635.865-1.28 1.298-1.92 2.989-4.432 5.97-8.872 8.97-13.298 1.442-2.126 3.92-2.997 6.542-2.35 2.21.545 4.05 2.682 4.326 5.037.039.338.047.681.047 1.021.003 14.334-.002 28.667.008 43 0 1.703-.468 3.199-1.669 4.436-.827.848-1.81 1.422-2.973 1.658-1.233.254-2.479.251-3.714.04-1.411-.242-2.549-.987-3.463-2.079-3.035-3.63-6.061-7.265-9.1-10.889-3.737-4.456-7.482-8.903-11.223-13.354-.885-1.052-1.76-2.116-2.646-3.17-3.824-4.554-7.651-9.104-11.476-13.657-.175-.209-.354-.408-.662-.283-.283.116-.297.36-.297.623.003 10.19 0 20.38.014 30.57 0 .212.125.544.277.605.162.065.472-.073.64-.215 3.637-3.125 7.264-6.267 10.896-9.4.167-.144.358-.26.54-.385.513-.345 1.094-.298 1.543.128.437.413.529.966.192 1.5-.453.723-.938 1.422-1.416 2.128-3.502 5.156-7.001 10.315-10.511 15.466-1.348 1.978-3.758 2.89-6.184 2.377-2.266-.48-4.098-2.361-4.533-4.657-.034-.178-.072-.356-.106-.536-.172-27.552-.172-42.502 0-44.85z"
                            transform="translate(-626 -332) translate(547 204) translate(59 108)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
